import { isPlainObject } from 'lodash';
import { HfPrefix, robotPrefix } from './index';
import { apiUrl, _base_axios_get, robotUrl } from '..';



export default async function (query = {}) {

    if (!isPlainObject(query)) {
        query = {}
    }

    return await _base_axios_get(`${apiUrl}/${robotPrefix}/getSymbolList`, query)

}