import {
  filter,
  get, isArray,
  isEmpty, reverse, slice, some, sortBy,
  toLower
} from "lodash";
import moment from "moment";

export const robotErrorType = {
  systemError: 0,
  apiKeyError: 1,
  exchangeError: 2,
  insufficientBuyFundError: 4,
  insufficientSellFundError: 5,
  duplicateBuy: 6,
  duplicateSell: 7,
  sendPlaceOrderFailed: 8,
};
export const binanceErrorCodes = {
  invalidAPIKey: "-2008",
  apiKeyPermissionDeny: "-2015",
  invalidAPIKeyFormat: "-2014",
  balanceInsufficient: "-2018",
};

export const binanceErrorCheckAction = {
  pending: 0,
  resolved: 1,
  rejected: 2,
  waitingUserRecover: 3,
  suspendRobot: 4,
};
export const robotStatusValue = {
  active: 0, //everything ok to run
  inactiveSystemRecovering: 1, // system fetching binance orders, to determine the order is success or not, after that should continue to run the robot
  inactiveAPIKeyError: 2, // stop the robot until user rebind apikey
  inactiveRobotSuspend: 3, // found violation action on user, suspend robot and deduct usdt
  inactiveUnknownError: 4, // unknown error stop the robot, admin should self restart manually
  inactiveExpiredCreate: 5, // take too long to create robot, stop robot
  inactiveDuplicateBuySell: 6, // take too long to create robot, stop robot,
  inactiveOnce: 7, // take too long to create robot, stop robot
  inactiveOverAttempt: 8, // take too long to create robot, stop robot
};

export const robotInactiveStatus = [
  robotStatusValue.inactiveAPIKeyError,
  robotStatusValue.inactiveRobotSuspend,
  robotStatusValue.inactiveSystemRecovering,
  robotStatusValue.inactiveUnknownError,
  robotStatusValue.inactiveExpiredCreate,
  robotStatusValue.inactiveDuplicateBuySell,
  robotStatusValue.inactiveOnce,
  robotStatusValue.inactiveOverAttempt,
];
export const robotConfirmedErrorStatus = [
  robotStatusValue.inactiveAPIKeyError,
  robotStatusValue.inactiveRobotSuspend,
  robotStatusValue.inactiveExpiredCreate,
];
export const robotUserCanRecoverErrorStatus = [
  robotStatusValue.inactiveAPIKeyError,
  robotStatusValue.inactiveExpiredCreate,
];

export const allowSimulateCoins = [
  "BTCUSDT",
  "ETHUSDT",
  "DOCKUSDT",
  "SOLUSDT",
  "APEUSDT",
  "RUNEUSDT",
  "BNBUSDT",
  "MATICUSDT",
  "DOTUSDT",
  "MANAUSDT",
];
export const robotTypes = ["normal", "averageControl"];
export const excludeBNB = ["BNBBULL", "BNBBEAR", "BNBUP", "BNBDOWN", "BUSD","BNX","DREP","MOB","TORN","TWT","BEAM"];
export const strategyCode = {
  highFrequency: "HF1",
  highFrequency3: "HF3",
  highFrequency4: "HF4",
};

export function checkRobotSetting(robot) {
  return get(robot, "loop") ? "循环 Loop" : "单次 Once";
}

export function getFormatedDate(date, showTime) {
  if (date) {
    return showTime
      ? moment(date).format("DD/MM/YYYY hh:mm:ss A")
      : moment(date).format("DD/MM/YYYY");
  }

  return date;
}

export function getRobotType(robot) {
  // return get(robot, 'average') === true && get(robot, 'type') == 1 ?
  //     robotTypes[1]
  //     :
  //     get(robot, 'type') == 1 ?
  //         robotTypes[0]
  //         :
  //         ''

  return get(robot, "strategy.code");
}

export function getFirstBuyingPoint(binanceStocks) {
  let result;
  if (isArray(binanceStocks) && !isEmpty(binanceStocks)) {
    binanceStocks = sortBy(binanceStocks, ["timestamp"]);
    result = get(binanceStocks, "[0].price");
  }
  return result;
}

export function getCurrentBuyingPoint(binanceStocks) {
  let result;

  let filteredList = filter(binanceStocks, function (o) {
    return o.side === "buy";
  });

  if (isArray(filteredList) && !isEmpty(filteredList)) {
    filteredList = sortBy(filteredList, ["timestamp"]).reverse();
    result = get(filteredList, "[0].price");
  }
  return result;
}

export function getBoughtStockAmountSum(binanceStocks) {
  let sum = 0;

  let filteredList = filter(binanceStocks, function (o) {
    return o.side === "buy";
  });

  if (isArray(filteredList) && !isEmpty(filteredList)) {
    filteredList.map((record) => {
      get(record, "cost")
        ? (sum = sum + parseFloat(get(record, "cost")))
        : (sum = sum + 0);
    });
  }
  return sum;
}

export function getFirstStock(binanceStocks) {
  let result;
  if (isArray(binanceStocks) && !isEmpty(binanceStocks)) {
    binanceStocks = sortBy(binanceStocks, ["timestamp"]);
    result = get(binanceStocks, "[0]");
  }
  return result;
}

export function getLatestBuyingPoint(binanceStocks) {
  let result;
  if (isArray(binanceStocks) && !isEmpty(binanceStocks)) {
    binanceStocks = sortBy(binanceStocks, ["timestamp"]);
    result = get(binanceStocks, "[0].price");
  }
  return result;
}

export function getActiveOrders(orders) {
  let boughtStocks = getBoughtBinanceStocks(orders);
  boughtStocks = filter(boughtStocks, (order) => {
    return !some(orders, [
      "clientOrderId",
      `${order.clientOrderId.replace("buy", "sell")}`,
    ]);
  });
  return boughtStocks;
}
export function getActiveBinanceStocks(orders) {
  let result;
  if (isArray(orders) && !isEmpty(orders)) {
    let soldStocks = filter(orders, (order) => {
      return toLower(get(order, "side")) === "sell";
    });

    let boughtStocks = getBoughtBinanceStocks(orders);
    return boughtStocks.length - soldStocks.length;
  }
  return result;
}

export function getBoughtBinanceStocks(orders) {
  let result = [];
  if (isArray(orders) && !isEmpty(orders)) {
    result = filter(orders, (order) => {
      return toLower(get(order, "side")) === "buy";
    });
  }
  return result;
}

export function getLatestTransactionDate(binanceStocks) {
  let result;
  if (isArray(binanceStocks) && !isEmpty(binanceStocks)) {
    binanceStocks = reverse(sortBy(binanceStocks, ["timestamp"]));
    result = get(binanceStocks, "[0].timestamp")
      ? getFormatedDate(moment(get(binanceStocks, "[0].timestamp")), true)
      : null;
  }
  return result;
}

export function analyzeOrders(orders) {
  let activeOrders = [];
  if (isArray(orders) && !isEmpty(orders)) {
    let origBuyOrders = filter(orders, (order) => {
      return order.side === "buy";
    });
    let origSellOrders = filter(orders, (order) => {
      return order.side === "sell";
    });
    let sellOrders = origSellOrders;
    activeOrders = filter(origBuyOrders, (order) => {
      let stockId = order.clientOrderId.split("-");
      stockId = slice(stockId, 0, stockId.length - 1);
      stockId = stockId.join("-");
      let sold = some(sellOrders, ["clientOrderId", `${stockId}-sell`]);
      if (sold) {
        let removedCount = 0;
        sellOrders = filter(sellOrders, (sellOrder) => {
          if (`${stockId}-sell` === sellOrder.clientOrderId) {
            removedCount += 1;
          }
          return (
            sellOrder.clientOrderId != `${stockId}-sell` ||
            (`${stockId}-sell` === sellOrder.clientOrderId && removedCount > 1)
          );
        });
      }

      return !sold;
    });

    return {
      buyOrders: origBuyOrders,
      sellOrders: origSellOrders,
      activeOrders: activeOrders,
      overSellOrders: sellOrders,
    };
  }

  return {};
}
